<script setup>
import { ref, reactive } from "vue";
// 导入图片上传组件
import imgUpload from "@/components/img-upload/img-upload.vue";
import { BasicApi } from "@/plugins/api.js";

/** 筛选条件列表 */
const filters = ref([
  {
    filterType: "select",
    name: "village",
    value: "",
    placeholder: "请选择项目",
    options: [
      {
        value: 1,
        label: "项目1",
      },
      {
        value: 2,
        label: "项目2",
      },
    ],
  },
  {
    filterType: "select",
    name: "hotel",
    value: "",
    placeholder: "请选择酒店",
    options: [
      {
        value: 1,
        label: "酒店1",
      },
      {
        value: 2,
        label: "酒店2",
      },
    ],
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "type",
    label: "房型ID",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "hotel",
    label: "房型名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "address",
    label: "房间数量",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "price",
    label: "默认价格",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    type: "switch",
    prop: "status",
    label: "房型状态",
    minWidth: 120,
  },
  {
    type: "image",
    prop: "imgList",
    label: "房型图片",
    minWidth: 150,
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 状态改变处理 */
const statusChange = (row) => {
  console.log(row);
};
/** 表格编辑 */
const comboEdit = (row) => {
  console.log(row);
  isEdit.value = true;
  addDialog.value.show();
};

/** 新增/编辑弹框 */
const addDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  village: "", // 项目
  hotel: "", // 酒店
  name: "", // 套餐名称
  number: "", // 房间数量
  price: "", // 默认价格
  tagVal: "", // 标签选用
});
/** 表单规则对象 */
const rules = reactive({
  village: [
    {
      required: true,
      message: "请选择所属项目",
      trigger: "change",
    },
  ],
  hotel: [
    {
      required: true,
      message: "请选择所属酒店",
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入房型名称",
      trigger: "blur",
    },
  ],
  number: [
    {
      required: true,
      message: "请输入房间数量",
      trigger: "blur",
    },
  ],
  price: [
    {
      required: true,
      message: "请输入默认价格",
      trigger: "blur",
    },
  ],
  tagVal: [
    {
      required: true,
      message: "请选择房型特色标签",
      trigger: "change",
    },
  ],
});
/** 弹出 新增/编辑房型 弹框 */
const showAddDialog = () => {
  isEdit.value = false;
  addDialog.value.show();
};
</script>

<template>
  <div class="parking main-cnt">
    <div class="title">房型列表</div>
    <div class="content">
      <common-table
        tableHeight="calc(100vh - 325px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="BasicApi.getUserByOrg"
        :filters="filters"
        :columns="tableColumns"
        @statusChange="statusChange"
        @edit="comboEdit"
      >
        <template #operate>
          <el-button type="primary" round @click="showAddDialog">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增房型</el-button
          >
        </template>
      </common-table>
    </div>

    <!-- 新增/编辑房型弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      :title="isEdit ? '编辑房型' : '新增房型'"
      width="55%"
      btnWidth="140px"
      top="10vh"
      @wConfirm="confirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="所属项目" prop="village">
          <el-select
            v-model="ruleForm.village"
            clearable
            placeholder="请选择所属项目"
          >
            <el-option label="项目一" value="1"></el-option>
            <el-option label="项目二" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属酒店" prop="hotel">
          <el-select
            v-model="ruleForm.hotel"
            clearable
            placeholder="请选择所属酒店"
          >
            <el-option label="酒店一" value="1"></el-option>
            <el-option label="酒店二" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="房型名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入房型名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="房间数量" prop="number">
          <el-input
            v-model="ruleForm.number"
            type="number"
            placeholder="请输入房间数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="默认价格" prop="price">
          <el-input
            v-model="ruleForm.price"
            type="number"
            placeholder="请输入默认价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="标签选用" prop="tagVal">
          <el-select
            v-model="ruleForm.tagVal"
            clearable
            placeholder="请选择房型特色标签"
          >
            <el-option label="标签一" value="1"></el-option>
            <el-option label="标签二" value="2"></el-option>
          </el-select>
        </el-form-item>

        <!-- 图片上传部分 -->
        <el-form-item class="upload" label="房型图片" prop="tagVal">
          <img-upload :limit="5" uploadTitle="房型图片"></img-upload>
        </el-form-item>
        <!-- 上传封面 -->
        <el-form-item class="upload" label="上传封面" prop="tagVal">
          <img-upload :limit="5"></img-upload>
        </el-form-item>
      </el-form>
    </w-dialog>
  </div>
</template>

<style lang="scss">
.parking {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .add-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0 15px 30px;
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 20px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
            }
          }
          .el-form-item.upload {
            width: 100%;
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }
}
</style>
